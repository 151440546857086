import { RouteNames } from '@/router/types';
import { type Router } from 'vue-router';
import { actions as oidcActions } from '../oidc';

export function replaceNativeFetch(router: Router) {
  const { fetch: originalFetch } = window;

  window.fetch = async (...args) => {
    const [resource, config] = args;

    const response = await originalFetch(resource, config);

    if (!response.ok) {
      switch (response.status) {
        case 404:
          await router.push({ name: RouteNames.NotFound });
          break;
          // return Promise.reject(response);

        case 401:
          await router.push({ name: RouteNames.OidcCallback });
          oidcActions.value.removeUser();
          window.location.reload();
          break;
          // return Promise.reject(response);

        default:
          break;
      }
    }

    return response;
  };
}
