import { useOidc } from '@/composables/oidc';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { RouteNames } from './types';

const routes = [
  {
    path: '/404',
    name: RouteNames.NotFound,
    component: () => import('../views/ErrorPage.vue'),
  },
  {
    path: '/oidc-callback',
    name: RouteNames.OidcCallback,
    component: () => import('../views/OidcCallback.vue'),
  },
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: RouteNames.Dashboard,
        component: () => import('../views/DashboardView.vue'),
      },
      {
        path: 'investors',
        children: [
          {
            path: '',
            name: RouteNames.Investors,
            component: () => import('../views/investors/InvestorsListView.vue'),
          },
          {
            path: ':id',
            name: RouteNames.InvestorDetails,
            component: () => import('../views/investors/InvestorDetailView.vue'),
          },
        ],
      },
      {
        path: 'returns',
        children: [
          {
            path: '',
            name: RouteNames.Returns,
            component: () => import('../views/returns/ReturnsListView.vue'),
          },

          {
            path: 'detail/:id',
            name: RouteNames.ReturnCollection,
            component: () => import('../views/returns/ReturnCollectionView.vue'),
          },

          {
            path: 'create',
            name: RouteNames.ReturnCollectionCreate,
            component: () => import('../views/returns/ReturnCollectionCreateView.vue'),
          },
        ],
      },
      {
        path: 'payments',
        name: RouteNames.Payments,
        component: () => import('../views/payments/PaymentsListView.vue'),
      },
      {
        path: 'issues',
        children: [
          {
            path: '',
            name: RouteNames.Issues,
            component: () => import('../views/issues/IssuesListView.vue'),
          },
          {
            path: ':id',
            name: RouteNames.IssuesDetails,
            component: () => import('../views/issues/IssueDetailsView.vue'),
          },
        ],
      },
      {
        path: 'companies',
        children: [
          {
            path: '',
            name: RouteNames.Companies,
            component: () => import('../views/companies/CompaniesView.vue'),
          },
          {
            path: ':id',
            name: RouteNames.CompaniesDetails,
            component: () => import('../views/companies/CompanyDetailView.vue'),
          },
        ],
      },
      {
        path: 'mediators',
        name: RouteNames.Mediators,
        component: () => import('../views/MediatorsView.vue'),
      },
      {
        path: 'users',
        name: RouteNames.Users,
        component: () => import('../views/UsersView.vue'),
      },
    ],
  },

  {
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    redirect: '/404',
  },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, _, next) => {
  if (to.name === RouteNames.OidcCallback && to.query.error === 'unauthorized_client') {
    useOidc().logout();
    return false;
  }

  const v2PathRegex = /^\/v2(\/|$)/;

  if (to.redirectedFrom?.fullPath.match(v2PathRegex)) {
    const newPath = to.redirectedFrom?.fullPath.replace(v2PathRegex, '/');

    if (newPath !== to.fullPath) {
      next({ path: newPath });
    } else {
      next();
    }
  } else {
    const { profile } = useOidc();

    const isMediatorRoute = to.name === RouteNames.Mediators;
    const isAuthorized = import.meta.env.VITE_MEDIATORS_OID?.split(',').map(Number).includes(profile.value?.oid);

    if (isMediatorRoute && !isAuthorized) {
      next({ name: RouteNames.Dashboard });
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  (window as any)?.productFruits?.pageChanged?.();
});

export default router;
