import { RouteLocationNormalized, useRouter } from 'vue-router';
import {
  inject, provide, ShallowRef, shallowRef,
} from 'vue';
import { RouteNames } from '@/router/types';
import { pick } from 'lodash-es';

export const usePrevRoute = () => inject('prev-route') as ShallowRef<RouteLocationNormalized | null>;

/**
 * @description Singleton function to provide previous route. Will be used in app.vue
 */
export function usePrevRouteInit() {
  const router = useRouter();
  const prevRoute = shallowRef<RouteLocationNormalized | null>(null);

  try {
    const prevRouteInSession = sessionStorage.getItem('prevRoute');
    if (prevRouteInSession) {
      prevRoute.value = JSON.parse(prevRouteInSession) as RouteLocationNormalized;
    }
  } catch (err) {
    // Nothing to do
  }

  router.afterEach((_, from) => {
    if (!from.name || !(Object.values(RouteNames) as string[]).includes(from.name as string)) { return; }

    prevRoute.value = from;

    try {
      sessionStorage.setItem('prevRoute', JSON.stringify(pick(from, ['name', 'params', 'query', 'hash', 'meta', 'path', 'fullPath'])));
    } catch (err) {
      // Nothing to do
    }
  });

  provide('prev-route', prevRoute);
}
