import { cs } from '@/shared/helpers/pluralRules/pluralRules';
import CKEditor from '@ckeditor/ckeditor5-vue';
import { createGtm } from '@gtm-support/vue-gtm';
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';
import Notifications from 'notiwind';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { registerSW } from 'virtual:pwa-register';
import App from './App.vue';
import './assets/styles/style.scss';
import './oidc';
import router from './router';
import messages from './translations';

import 'dayjs/locale/cs';

dayjs.locale('cs');

declare const APP_NAME: string;
declare const APP_VERSION: string;

const i18n = createI18n({
  locale: 'cs',

  messages,

  pluralRules: {
    cs,
  },

  numberFormats: {
    cs: {
      currency: {
        style: 'currency',
        currency: 'CZK',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },

      currencyWithCents: {
        style: 'currency',
        currency: 'CZK',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },

      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },

      percent: {
        style: 'percent',
        maximumFractionDigits: 2,
      },
    },
  },
  datetimeFormats: {
    cs: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },

      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
  missing(locale, key) {
    Sentry.captureMessage(`Missing translation for ${key} in ${locale}`);
  },
});

const app = createApp(App);

// eslint-disable-next-line no-console
console.log(`Current version: ${APP_VERSION}`);

Sentry.init({
  app,
  release: `${APP_NAME}@${APP_VERSION}`,
  dsn: import.meta.env.VITE_SENTRY_DSN || '',
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  trackComponents: true,
  logErrors: true,
  tracesSampleRate: 1.0,
});

const intervalMS = 60 * 1000;

registerSW({
  onRegisteredSW(swUrl, r) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    r && setInterval(async () => {
      if (!(!r.installing && navigator)) { return; }

      if (('connection' in navigator) && !navigator.onLine) { return; }

      try {
        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache',
          },
        });
        if (resp?.status === 200) { await r.update(); }
      } catch (error) {
        Sentry.captureException(error);
      }
    }, intervalMS);
  },
});

const pinia = createPinia();

app
  .use(router)
  .use(i18n)
  .use(pinia)
  .use(CKEditor)
  .use(Notifications);

if (import.meta.env.VITE_GTM_ID) {
  app.use(createGtm({
    id: import.meta.env.VITE_GTM_ID,
    debug: ['dev', 'local'].includes(import.meta.env.VITE_ENVIRONMENT),
    // vueRouter: router,
  }));
}

app.mount('#app');
