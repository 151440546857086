// @ts-nocheck
const nav = (await import('./nav.json5')).default;
const app = (await import('./app.json5')).default;
const common = (await import('./common.json5')).default;
const users = (await import('./users.json5')).default;
const issues = (await import('./issues.json5')).default;
const companies = (await import('./companies.json5')).default;
const bankAccounts = (await import('./bankAccounts.json5')).default;
const mediators = (await import('./mediators.json5')).default;
const search = (await import('./search.json5')).default;
const payments = (await import('./payments.json5')).default;
const orders = (await import('./orders.json5')).default;
const investors = (await import('./investors.json5')).default;
const address = (await import('./address.json5')).default;
const dashboard = (await import('./dashboard.json5')).default;
const returns = (await import('./returns.json5')).default;
const notifications = (await import('./notifications.json5')).default;
const oidcCallback = (await import('./oidcCallback.json5')).default;

export default {
  nav,
  app,
  common,
  users,
  issues,
  companies,
  bankAccounts,
  mediators,
  search,
  payments,
  orders,
  investors,
  dashboard,
  address,
  returns,
  notifications,
  oidcCallback,
};
