import * as Sentry from '@sentry/vue';

const WAIT_TO_NEXT_TRY_MS = 50;
const MAX_TRY_COUNT = new Array(5).fill(null).map((_, i) => i);

const wait = (ms: number) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export async function loadSvgSprite() {
  let isSpriteLoaded = false;

  for await (const i of MAX_TRY_COUNT) {
    try {
      if (isSpriteLoaded) { break; }

      if (i > 0) { await wait(WAIT_TO_NEXT_TRY_MS); }
      const path = '/static/sprite.svg';
      const response = await fetch(path);
      const sprite = await response.blob();

      if (sprite) {
        const reader = new FileReader();

        reader.readAsText(sprite);

        // eslint-disable-next-line @typescript-eslint/no-loop-func
        reader.onload = (e) => {
          isSpriteLoaded = true;

          const el = document.createElement('div');
          el.classList.add('sprite-container');
          el.innerHTML = (e.target as any).result as string;
          document.body.appendChild(el);
        };
      }
    } catch (err) {
    // Nothing to do
    }
  }

  // CAPTURE EXCEPTION IF SVG SPRITE WAS NOT LOADED AFTER MAX TRY COUNT
  if (!isSpriteLoaded) {
    Sentry.captureException(new Error('SVG sprite was not loaded'));
  }
}
