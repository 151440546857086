export enum RouteNames {
  OidcCallback = 'OidcCallback',
  Users = 'users',
  Dashboard = 'dashboard',
  Investors = 'investors',
  InvestorDetails = 'InvestorDetails',
  Returns = 'returns',
  ReturnCollection = 'return-collection',
  ReturnCollectionCreate = 'return-collection-create',
  Payments = 'payments',
  Issues = 'issues',
  IssuesDetails = 'issues-details',
  Companies = 'companies',
  CompaniesDetails = 'companies-details',
  Mediators = 'mediators',
  NotFound = 'not-found',
}
