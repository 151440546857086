import { unref } from 'vue';
import type { UserProfile, VueOidcSettings } from 'vue3-oidc';
import { createOidc, useOidcStore } from 'vue3-oidc';

export type CfgProfile = UserProfile & {
  first_name: string;
  last_name: string;
  oid: number;
  organizations: [Record<number, string>];
};

const { state, actions } = useOidcStore<CfgProfile>();

const oidcSettings = {
  authority: import.meta.env.VITE_OIDC_AUTHORITY,
  scope: 'openid profile',
  client_id: import.meta.env.VITE_OIDC_CLIENT_ID,
  client_secret: import.meta.env.VITE_OIDC_CLIENT_SECRET,
  redirect_uri: `${import.meta.env.VITE_LOCATION}/oidc-callback`,
  response_type: 'code',
  loadUserInfo: true,
  onSigninRedirectCallback: () => {
    let redirectUri = unref(state).redirect_uri || '/';

    if (redirectUri.includes('oidc-callback')) {
      redirectUri = '/';
    }

    window.location.href = redirectUri;
  },
} satisfies VueOidcSettings;

createOidc({
  oidcSettings,
  auth: true,
  refreshToken: {
    enable: true,
    time: 30000,
  },
});

export { actions, state };
