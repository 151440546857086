<script lang="ts" setup>
import { replaceNativeFetch } from '@/helpers/fetchInterceptor';
import { usePrevRouteInit } from '@/shared/composables/usePrevRoute';
import { CfgPreloader, CfgToastNotification } from '@cfgtech/ui';
import { Notification, NotificationGroup } from 'notiwind';
import { useRouter } from 'vue-router';
import { loadSvgSprite } from './helpers/loadSvgSprite';
import { useAppStore } from './shared/stores/appStore';

replaceNativeFetch(useRouter());

const appStore = useAppStore();
usePrevRouteInit();

loadSvgSprite();

window.addEventListener('vite:preloadError', (event: any) => {
  event.preventDefault();
  window.location.reload();
});
</script>

<template>
  <div class="flex max-w-full">
    <RouterView />
  </div>

  <div v-if="appStore.showOverlay" class="absolute inset-0 z-globalOverlay flex h-screen w-screen items-center justify-center bg-black/75">
    <CfgPreloader class="text-[1.25rem] text-white" />
  </div>

  <NotificationGroup group="app">
    <div class="pointer-events-none fixed inset-0 z-notificationTab flex items-start justify-end p-6 px-4">
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications, close }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-200"
          leave-from="translate-y-0 opacity-100 sm:translate-x-0"
          leave-to="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="mt-2"
          >
            <CfgToastNotification
              :message="notification.text"
              :title="notification.title"
              :type="notification.type"
              @close="close(notification.id)"
            />
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>
