import { computed } from 'vue';
import { state as oidcState, actions as oidcActions } from '../oidc';

export function useOidc() {
  const profile = computed(() => oidcState.value.user?.profile);

  const logout = async () => {
    oidcActions.value.removeUser();
    window.location.href = import.meta.env.VITE_OIDC_LOGOUT_URL;
  };

  return {
    profile,
    logout,
  };
}
