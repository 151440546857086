export function cs(choice: number): number {
  if (choice === 0) return 2;

  if (choice === 1) return 0;

  if (choice >= 2 && choice <= 4) {
    return 1;
  }

  return 2;
}
